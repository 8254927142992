<template>
  <v-card class="page-container admin-employees-list pa-5">
      <vue-breadcrumbs :breadCrumbs='breadcrumbItems' />
    <h1 class="my-3">
      <span class="label primary--text mr-4">Cотрудники</span>
      <span class="name secondary--text">{{ details.name }}</span>
    </h1>
    <v-data-table
      style="max-width:none"
      :headers="headers"
      :items="items.results"
      :server-items-length="items.count"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="primary" @click="changePass(item)">изменить пароль</v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" width="500">
      <v-card class="pa-4">
        <h2 style="text-align:center;">Новый пароль</h2>
        <div class="inputs">
          <v-text-field type="password" v-model="password.password" label="Новый пароль" />
          <v-text-field type="password" v-model="password.password1" label="Повторите пароль" />
          <div class="mt-4">
            <v-btn :loading="btnLoading" @click="confimPass" class="mr-3" color="primary">Сохранить</v-btn>
            <v-btn color="primary" outlined @click="dialog = false">Отмена</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import VueBreadcrumbs from '@/components/ui/vueBreadcrumbs.vue'
import { companiesService } from '@/services/companies.service.js'

export default {
  components: {
    VueBreadcrumbs,
  },
  data() {
    return {
      btnLoading: false,
      current: null,
      breadCrumbs: [], 
      details: {}, 
      dialog: false,
      items: [], 
      password: {},
      headers: [
        {
          text: "ФИО",
          value: "full_name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          text: "Турагент",
          to: "/tourAgent",
        },
        {
          text: this.details.name || "Компания",
        },
      ];
    },
  },

  created(){
      this.getDetails()
  },
  methods: {
    async getDetails() {
        try {
            this.$loading(true)
            this.items =  await companiesService.getTourEmployees(this.$route.params.id)
            this.breadCrumbs = [{text: 'Турагенты',to: `/tourAgent`}, {text: '' ,to: `/`}]
            this.details = await companiesService.getSingleCompany(this.$route.params.id)
            console.log(this.details);
        }catch(e) {
            console.error(e);
        } finally {
            this.$loading(false)
        }
    },
    async confimPass() {
        if(this.password.password != this.password.password1) {
            this.$store.commit("ui/SET_NOTIFICATION", {
                show: true,
                message: "Пароли должны совпадать!",
                color: "error",
            });
            return;
        }
        this.btnLoading = true;
        await companiesService.changePassword({
            user: this.current.id, 
            password: this.password.password
        })
        this.$store.commit("ui/SET_NOTIFICATION", {
            show: true,
            message: "Успешно изменено!",
            color: "success",
        });
        this.current = null;
        this.dialog = false;
        this.btnLoading = false;
    },
    changePass(item) {
        this.current = item;
        this.dialog = true;
    },
  },
};
</script>

<style lang="scss">

</style>
